<template>
  <div class="doc-right">
      <div class="doc-article">
          <h1 class="tit">{{ $t('msg.single_title')  }}</h1>
          <h4>接口描述</h4>
          <p class="text">
            翻译接口，为了翻译接口更加安全保密，同时v1老接口需要继续使用。
            所以开展v2翻译接口，有保密和防破解功能，同时对接口做时间ip限制访问量。v1接口后续会在迭代中删除掉
          </p>
          <h4>接口地址</h4>
          <pre><code>POST https://api.translasion.com/v2/translate</code></pre>
          <h4>接口加密操作方式</h4>
          <p class="text mb-15">使用者被授权后，工作人员提供名字、包名等信息向后台申请，获取app_key和secret。app_key用于区分是用户，secret用于加密和进行验证身份。 客户端加密</p>
          <pre class="mb-15"><code>nonce 四位随机字符串
str = sprintf("%s&%s&%s&%s&%s", app_key, to, timestamp, secret, nonce)
sig = md5(str)</code></pre>
          <p class="text">顺序要一致</p>
          <h4>请求参数</h4>
          <el-table
              :data="apiData"
              stripe
              style="width: 100%"
              class="mb-15">
              <el-table-column
              prop="name"
              label="参数名称"
              >
              </el-table-column>
              <el-table-column
              prop="type"
              label="类型"
              >
              </el-table-column>
              <el-table-column
              prop="isMust"
              label="是否必要">
              </el-table-column>
              <el-table-column
              prop="explain"
              label="参数说明"
              >
            </el-table-column>
          </el-table>
          <pre><code>{
    "app_key": "10025""to":"en",
    "text":"你好啊",
    "from":"",
    "timestamp": 1231234213,
    "nonce": "acbf""sig":"feafefaefaefef"
}</code></pre>
        <h4>响应参数</h4>
        <h5 class="mb-15">示例：</h5>
        <p class="text mb-15">1000表示成功 其他表示失败 错误信息提示</p>
        <el-table
          :data="errData"
          stripe
          style="width: 100%"
          class="mb-15">
          <el-table-column
            prop="info"
            label="错误信息"
            >
          </el-table-column>
          <el-table-column
            prop="explain"
            label="说明">
          </el-table-column>
        </el-table>
        <pre><code>{
    "code": "1000",
    "message":"success",
    "result": {
        "text":"hello"
    }
}</code></pre>
      </div>
      <div class="docs-subnav">
        <a href="/docs/api/text/multiple">
          <i class="el-icon-arrow-left"></i>
          多文本翻译
        </a>
        <a href="/docs/api/text/languageList">
          文本翻译语言列表
          <i class="el-icon-arrow-right"></i>
        </a>
      </div>
  </div>
</template>

<script>
export default {
  name: 'singleText',
  data () {
    return {
      apiData: [{
        name: 'app_key',
        type: 'string',
        isMust: '是',
        explain: 'app_key平台分配'
      }, {
        name: 'to',
        type: 'string',
        isMust: '是',
        explain: '目标语言代码'
      }, {
        name: 'text',
        type: 'string',
        isMust: '是',
        explain: '一个需要翻译的字符串'
      }, {
        name: 'from',
        type: 'string',
        isMust: '否',
        explain: '目标源语言，不填写自动检测'
      }, {
        name: 'timestamp',
        type: 'int',
        isMust: '是',
        explain: '时间戳（秒）当前时间戳，5分钟后过期'
      }, {
        name: 'nonce',
        type: 'string',
        isMust: '是',
        explain: '随机字符串（4位）'
      }, {
        name: 'sig',
        type: 'string',
        isMust: '是',
        explain: '生成后的验证码'
      }],
      errData: [
        {
          info: 'app key incorrect',
          explain: 'app_key传递错误'
        },
        {
          info: 'token expired',
          explain: '时间戳过期，使用当前时间戳（秒）'
        }, {
          info: 'sig incorrect',
          explain: 'md5加密验证错误'
        }
      ]
    }
  }
}
</script>

<style scoped>
  .doc-right{
      padding:50px 0 0;
  }
  .tit{
      font-size: 26px;
      color: #333;
      font-weight: 400;
      margin: 0 0 60px;
  }
  .doc-article{
      color: #5e6d82;
      font-size: 14px;
  }
  .text{
      line-height: 1.5em;
  }
  .doc-article h4{
      margin: 55px 0 15px;
      font-size: 18px;
      font-weight: 700;
  }
  .doc-article h5{
      font-size: 16px;
      font-weight: 700;
  }
  .mb-15{
      margin-bottom: 15px;
  }
  .doc-article code{
      display: block;
      overflow-x: auto;
      line-height: 1.5;
      font-family: Menlo, Monaco, Consolas, Courier, monospace;
      font-size: 13px;
      padding: 18px 24px;
      background-color: #fafafa;
      border: 1px solid #eaeefb;
      border-radius: 4px;
      -webkit-font-smoothing: auto;
  }
  .red{
      color: red;
  }
  .docs-subnav {
        margin-top: 100px;
        padding: 40px 0 120px;
        border-top: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
    }
    .docs-subnav>a {
        position: relative;
        color: #333;
        opacity: .7;
        font-size: 20px;
    }
    .docs-subnav>a:hover{
      opacity: 1;
    }
    .docs-subnav>.r {
        margin-right: 15px;
    }
    .docs-subnav>.r>i {
        left: auto;
        right: -15px;
        margin-top: 1px;
        transform: rotate(135deg);
    }
    a{
      text-decoration: none;
    }
</style>
